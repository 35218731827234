import React, { useState, useEffect } from 'react'

import clsx from 'clsx'

import { styled } from '@mui/material/styles'
import { withStyles } from '@mui/styles'
import { InputAdornment, IconButton, OutlinedInput } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

const styles = (theme) => ({})

const SearchInput = ({
  classes,
  value = '',
  onChange = () => undefined,
  showSearchIcon = false,
  ...inputProps
}) => {
  const [tempValue, setTempValue] = useState('')

  useEffect(() => {
    setTempValue(value)
  }, [value])

  const handleClear = () => {
    setTempValue('')
    onChange('')
  }

  const handleKeyPress = (e) => {
    if (e.code === 'Enter') {
      onChange(tempValue)
      document.activeElement.blur()
    }
  }

  useEffect(() => {
    let isSearch = true
    if (isSearch) {
      onChange(tempValue)
    }
    return () => {
      isSearch = false
    }
  }, [tempValue])

  return (
    <OutlinedInput
      {...inputProps}
      value={tempValue}
      onChange={(e) => setTempValue(e.target.value)}
      onKeyPress={handleKeyPress}
      startAdornment={
        showSearchIcon && (
          <InputAdornment position="start">
            <i
              className={clsx(
                'fa-solid fa-magnifying-glass',
                classes.searchIcon
              )}
            />
          </InputAdornment>
        )
      }
      endAdornment={
        tempValue.length > 0 && (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="clear input"
              edge="end"
              size="small"
              onClick={handleClear}
              // sx={{ p: 0.5 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  )
}

export default withStyles(styles, { name: 'AMSSearchInput' })(SearchInput)
