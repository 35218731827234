import { surveyShared } from '../shared/survey'

import keyBy from 'lodash/keyBy'

const questions = [
  ...surveyShared.questions,
  {
    id: 'categories',
    type: 'categories',
    label: 'Categories',
    icon: 'far fa-stream',
    question: {
      self: 'What are you interested in?',
      other: 'What are they interested in?',
    },
  },
]

export const survey = {
  questions,

  // TODO: move this and the merge to the config loader
  questionsMap: keyBy(questions, 'id'),
}
