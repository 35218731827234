import _ from 'lodash'
import { ServiceResult } from './ServiceResult.model'
import { formatDistance, convertServiceChanges, formatAddress } from './common'
import { ApiSearchResultModel } from '@ciss/cie-api-orval'
import { SetRequired } from 'type-fest'

/**
 * Transformer to convert CieApi Service to {@link ServiceResult}
 *
 * @category Models
 */
export const serviceResultTransformer = (data: ApiSearchResultModel) => {
  try {
    const cieService = data as SetRequired<
      ApiSearchResultModel,
      'outlet' | 'service'
    >

    if (!cieService) {
      console.warn(`Could not assert CieDirectoryResult`, data)
      return
    }

    const { outlet, service } = cieService

    const latLng = {
      lat: outlet?.geocodeLat || 0.0,
      lng: outlet?.geocodeLong || 0.0,
    }

    const distance = parseFloat(cieService.distance || '0')

    const result: ServiceResult = {
      type: 'service',
      id: `service_${service?.id}`,
      organisationId: cieService.organisationID || 0,
      outletId: outlet.outletID || 0,
      outletNickname: outlet.nickname || '',
      serviceId: service?.id || 0,
      category: cieService.category?.name,
      operatingDays: service.operatingDaysDisplay,
      operatingDaysArray: service.operatingDays,
      name:
        service.serviceTitle && service.serviceTitle.length > 0
          ? service.serviceTitle
          : outlet.nickname || '',
      organisationName: cieService.organisationName,
      address: outlet.displayAddress || '',
      addressFields: formatAddress(outlet.address),
      addressWithheld: outlet.displayAddress === '--withheld--',
      phone: outlet.phone || '',
      description: cieService.description || '',
      commissionedServiceId: service.commissionedServiceId,
      url: cieService.url,
      logoUrl: outlet.logoUrl,
      healthEngineId: outlet.healthEngineID,
      healthEngineStatus: outlet.healthEngineStatus,
      // TODO!! move to component
      // healthEngineAppointment: s.healthEngineAppointment,
      regions: outlet.regions || [],
      serviceRegionNames: service.serviceRegionNames || [],
      serviceChanges: convertServiceChanges(outlet.serviceChanges),
      serviceTags: service.serviceTags?.map((t) => t.tagID as number) || [],
      serviceCohortTags:
        service.serviceTags
          ?.filter((t) => t.tagOwnerType === 'Cohort')
          .map((t) => t.tagName as string) || [],
      networks: cieService.networks,

      distance,
      distanceLabel: formatDistance(distance),

      ...latLng,
      geometry: latLng,
    }

    return result
  } catch (e) {
    console.warn(`Failed to transform service id: ${data?.service?.id}`)
  }
}
